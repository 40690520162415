import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Minamoto',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
    description: `Minamoto engaged with my team and I at Ghost Pxl to develop a new brand identity across web and video. Minamoto is a high end Japanese restaurant in the heart of Downtown Bellevue. Their main draw is their Omakase experience.We produced and filmed a short video that captured the customers experience. We took a minimalistic approach to their website design, using blank space to move the user through the desktop and mobile experience.`,
    link: 'minamotowa.com',
  },
  caseId: 'minamoto',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    {
      jwplayer: {
        videoSrc: 'https://content.jwplatform.com/videos/LoOwOS34-OmmZTDe4.mp4',
        imageSrc: 'https://content.jwplatform.com/thumbs/LoOwOS34-1920.jpg',
      },
    },
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
